<template>
  <Container>
    <b-row align-h="center">
      <b-col md="4">
        <Card padding="medium">
          <Margins>
            <h1 class="h3 text-center">{{ $t('LOGIN') }}</h1>

            <form @submit="handleSubmit" class="margins__double">
              <Margins>
                <InputField
                  :label="`${$t('FORM.EMAIL')}`"
                  name="email"
                  v-model.trim="formData.username"
                  :error="errors.first('email')"
                  v-validate="{ required: true }"
                  :data-vv-as="$t('FORM.VALIDATOR.EMAIL')"
                  autocomplete="username"
                />
                <InputField
                  :label="`${$t('FORM.PASSWORD')}`"
                  name="password"
                  type="password"
                  v-model.trim="formData.password"
                  :error="errors.first('password')"
                  v-validate="{ required: true }"
                  :data-vv-as="$t('FORM.VALIDATOR.PASSWORD')"
                  autocomplete="current-password"
                  class="margins__double"
                />
                <div class="text-center margins__double">
                  <Button type="submit">{{ $t('CONTINUE') }}</Button>
                </div>

                <div class="margins text-center text-small margins__double">
                  <router-link
                    :to="this.ROUTES.BACK_OFFICE_PASSWORD_LOST.path"
                    :exact="true"
                    event="click"
                    title="Forgot password"
                    >{{ $t('FORGOT_PASSWORD') }}
                  </router-link>
                </div>
              </Margins>
            </form>
          </Margins>
        </Card>
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Card, Container, InputField, Margins, Button } from '@/components';
import { mapActions, mapState } from 'vuex';
import { BACK_OFFICE_LOGIN } from '@/types';
import { constants } from '@/mixins';

export default {
  name: 'BackOfficeLogin',
  mixins: [constants],
  data() {
    return {
      formData: {},
    };
  },
  components: {
    Container,
    Card,
    Margins,
    InputField,
    Button,
  },
  computed: {
    ...mapState(['backOfficeLoggedIn']),
  },
  methods: {
    ...mapActions([BACK_OFFICE_LOGIN]),
    async handleSubmit(e) {
      e.preventDefault();

      const valid = await this.$validator.validateAll();

      if (!valid) {
        return false;
      }

      await this.BACK_OFFICE_LOGIN(this.formData);

      this.formData.password = '';
      this.$validator.reset();

      if (this.backOfficeLoggedIn) {
        this.$router.push({ name: this.ROUTES.BACK_OFFICE.name });
      }
    },
  },
};
</script>
